import React, { useState, useEffect } from "react";
import instance from "../../utils/axiosConfig";
import { useSelector } from "react-redux";

const Home = () => {
	const [courses, setCourses] = useState([]);
	const [videos, setVideos] = useState([]);
	const [expanded, setExpanded] = useState(false);
	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		const fetchData = async () => {
			const videosData = await getCourses(user);
			setVideos(videosData);
		};

		fetchData();
	}, [user]);

	const getCourses = async (user) => {
		const courses = user?.courses ? user.courses.map(c => c.name) : [];
		setCourses(courses);
		console.log('user', user, 'courses', courses);

		const requests = courses.map(course =>
			instance.get(`/videos?sort=title:asc&filters[course][$eq]=${course}`)
		);

		try {
			const results = await Promise.all(requests);
			return results.flatMap(res => res.data.data);
		} catch (error) {
			console.error("Error fetching videos:", error);
			return []; // Return an empty array in case of an error
		}
	};

	return (
		<section>
			<div style={{ padding: "10px" }}>
				<h1>Welcome!</h1>
			</div>
			<div style={{ display: "flex", flexWrap: "wrap" }}>
				{courses.map((course, index) => (
					<div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
						<h3 style={{ width: "100%", padding: "10px" }}>{course}</h3>
						{videos.filter((v) => v.attributes?.course === course).map((video, index) => (
							<div key={video.id} style={{ width: video.id === expanded ? "100%" : "33.33%", padding: "10px", ...(video.id === expanded ? { position: "fixed", overflow: "auto", zIndex: 10, padding: 50, paddingLeft: "10%", paddingRight: "10%", top: 0, left: 0, right: 0, bottom: 0, background: "#ffffffe7" } : {}) }}>
								<div className="blk-videos" >
									<div style={{ display: "flex", flex: 1, justifyContent: 'space-between' }}>
										<div className="blk-title">{video.attributes.title || ""}</div>
										<button onClick={() => setExpanded((p) => {
											if (video.id === p) {
												return null
											} else {
												return video.id
											}
										})}>{video.id === expanded ? "Close" : "Open"}</button>
									</div>
									<div className={video.id === expanded ? "blk-icontent expanded" : "blk-icontent"} dangerouslySetInnerHTML={{ __html: video.attributes?.link || "" }} />
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		</section>
	);
};
export { Home };
