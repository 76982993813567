import Styles from "./app.module.scss";
import { Route, Routes } from "react-router-dom";

import { PrivateRoute } from "../privateRoute";
import { PublicRoute } from "../publicRoute";

import { Home } from "../../pages/home";
import { Login } from "../../pages/login";

function App() {
	return (
		<div>
			<Layout>
				<Routes>
					<Route path="/" element={<PrivateRoute component={Home} />} />
					<Route path="/login" element={<PublicRoute restricted={true} component={Login} />} />
				</Routes>
			</Layout>
		</div>
	);
}

const Layout = ({ children }) => (
	<div className={Styles.mainContainer}>
		<div className={Styles.contentContainer}>{children}</div>
	</div>
);

export { App };
